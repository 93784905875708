import React from "react"
import PageTitle from "../components/shared/PageTitle"

const Schedule = () => {
  return (
    <div>
      <PageTitle>Schedule</PageTitle>
      <div className="min-h-screen flex flex-col">
        <iframe
          title="Hockey Schedule "
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRVU3TdoYw0mr27B5Yq8xxQvXVUiZkm_7TmJvzIc_8SO-b68IdUBhi3zvmUocZYToMwaO-F9WLdlL0a/pubhtml?gid=39908428&single=true"
          className="h-full w-full border-none overflow-auto resize-both flex-grow px-5"
        />
      </div>
    </div>
  )
}

export default Schedule
